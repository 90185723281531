import Model from './Model'

export default class ConstructionWorkerModel extends Model {
  public id: number|undefined
  public date_of_birth?: string|null

  resource () : string {
    return 'constructionWorkers'
  }
}
