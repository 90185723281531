























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import SecureLS from 'secure-ls'
import User from '@/types/user'

type Tag = {
  id: number
  name: string
  count: number
}

@Component
export default class Tagger extends Vue {
  @Prop({ type: String, required: false }) model!: string | null
  @Prop({ type: Array, required: false, default: () => [] }) activeTagIds!: number[]

  @Watch('activeTagIds')
  onActiveTagIdsChange () : void {
    this.updateSelectedTagsIds()
  }

  ls = new SecureLS({ isCompression: false })
  tags:Tag[] = []
  selectedTagIds:number[] = []
  selectedDeleteTag: Tag = { id: 0, name: '', count: 0 }
  deleteModalBusy = false
  isFetchingTags = false
  deleteModalVisible = false

  mounted () : void {
    this.getTags()
    this.updateSelectedTagsIds()
  }

  updateSelectedTagsIds (): void {
    this.selectedTagIds = this.activeTagIds
  }

  getTags (): void {
    if (this.isFetchingTags) {
      return
    }

    this.isFetchingTags = true
    const url = this.model !== null ? `${process.env.VUE_APP_API_URL}/tags/${this.model}` : `${process.env.VUE_APP_API_URL}/tags/recent`
    axios.get(url)
      .then(response => {
        this.tags = response.data as Tag[]
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        this.isFetchingTags = false
      })
  }

  isSelected (tagId: number): boolean {
    return this.selectedTagIds.includes(tagId)
  }

  isMissing (tagId: number): boolean {
    return !this.activeTagIds.includes(tagId)
  }

  clearSelectedTags (): void {
    this.selectedTagIds = []
    this.selectedTagsUpdated()
  }

  handleTagClick (tagId: number): void {
    if (this.selectedTagIds.includes(tagId)) {
      this.selectedTagIds = this.selectedTagIds.filter(i => i !== tagId)
    } else {
      this.selectedTagIds.push(tagId)
    }

    this.selectedTagsUpdated()
  }

  handleRightClick (tag: Tag): void {
    this.selectedDeleteTag = tag
    this.deleteModalVisible = true
  }

  deleteTag (): void {
    this.deleteModalBusy = true
    axios.delete(`${process.env.VUE_APP_API_URL}/tags/${this.selectedDeleteTag.id}`)
      .then(() => {
        this.tags = this.tags.filter(tag => tag.id !== this.selectedDeleteTag.id)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        this.deleteModalBusy = false
        this.deleteModalVisible = false
      })
  }

  selectedTagsUpdated (): void {
    this.$emit('change', this.selectedTagIds)
  }

  get canEdit (): boolean {
    return this.authUser.permissions.includes('issues.tags.edit')
  }

  get authUser (): User {
    return this.ls.get('authUser')
  }
}
