














































































import { Vue, Component, Watch } from 'vue-property-decorator'
import SecureLS from 'secure-ls'
import User from '@/types/user'
import Column from '@/types/column'
import TableSettings from '@/types/tableSettings'
import ActiveFilters from '@/components/listview/ActiveFilters.vue'
import Pagination from '@/types/pagination'
import Objection from '@/types/objection'
import moment from 'moment/moment'
import axios from 'axios'
import ColumnOptions from '@/components/listview/ColumnOptions.vue'
import ObjectionBulkUpdater from '@/components/listview/ObjectionBulkUpdater.vue'
import { PriorityFilter, ServiceStates } from '@/types/serviceItem'
import SubscribeButton from '@/components/base/SubscribeButton.vue'
import PageHeader from '@/components/base/PageHeader.vue'
import SearchableColumnFilter from '@/components/base/SearchableColumnFilter.vue'

@Component({
  components: { SearchableColumnFilter, PageHeader, SubscribeButton, ObjectionBulkUpdater, ColumnOptions, ActiveFilters }
})

export default class Objections extends Vue {
  moment = moment
  ls = new SecureLS({ isCompression: false })
  combinedList = false
  serviceTypes = [
    { label: 'Meldingen', value: 'issues' },
    { label: 'Vragen', value: 'questions' },
    { label: 'Bezwaren', value: 'objections' }
  ]

  config = {
    entity: 'objection',
    entities: 'objections',
    plural: 'Bezwaren',
    single: 'Bezwaar',
    subtitle: 'Overzicht van bezwaren.'
  }

  settings = {
    routeNames: { create: 'objectionCreate', show: 'objectionShow', edit: 'objectionEdit' },
    routeParamKey: 'objectionId',
    permissionNames: { show: 'objections.view', edit: 'objections.edit', delete: 'objections.delete' },
    functionNames: { getList: 'getObjectionList' },
    getterNames: { list: 'objectionList', loading: 'loadingObjection' },
    localStorageName: 'objectionTableSettings'
  }

  sortOrder = { column: 'created_at', order: 'descend' }

  conditions = {
    status: [ServiceStates.Gesloten, ServiceStates.Beantwoord, ServiceStates.Afgewezen]
  }

  tableSettings:TableSettings = this.ls.get(this.settings.localStorageName) || new TableSettings(this.conditions, this.sortOrder)
  currentTab:string = this.ls.get('objectionsListActiveTab') || 'details'

  columns:Column[] = [
    {
      title: '#',
      key: 'id',
      dataIndex: 'id',
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Bedrijf',
      key: 'project.entity.id',
      dataIndex: 'entity',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'I.B.',
      key: 'manager_id',
      dataIndex: 'manager',
      scopedSlots: { customRender: 'manager', filterDropdown: 'customFilter' },
      onFilterDropdownVisibleChange: (visible:boolean):void => this.filterDropdownVisibleChange(visible),
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      width: 90
    },
    {
      title: 'Onderwerp',
      key: 'title',
      dataIndex: 'title',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Project',
      key: 'project.id',
      dataIndex: 'project',
      scopedSlots: { customRender: 'project', filterDropdown: 'customFilter' },
      onFilterDropdownVisibleChange: (visible:boolean):void => this.filterDropdownVisibleChange(visible),
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Adres',
      key: 'address',
      dataIndex: 'address',
      filters: [],
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Bwnr',
      key: 'building.number',
      dataIndex: 'building.number',
      filters: [],
      scopedSlots: { filterDropdown: 'customFilter' },
      onFilterDropdownVisibleChange: (visible:boolean):void => this.filterDropdownVisibleChange(visible),
      sorter: true,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Prio',
      key: 'priority',
      dataIndex: 'priority',
      scopedSlots: { customRender: 'priority' },
      filters: PriorityFilter,
      sorter: false,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Gemeld',
      key: 'created_at',
      dataIndex: 'created_at',
      scopedSlots: { customRender: 'created_at' },
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 110
    },
    {
      title: 'Deadline',
      key: 'estimate',
      dataIndex: 'estimate',
      scopedSlots: { customRender: 'estimate' },
      filters: [],
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 110
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      scopedSlots: { customRender: 'status' },
      filters: [
        { text: 'Nieuw', value: 0 },
        { text: 'In behandeling', value: 1 },
        { text: 'Akkoord', value: 3 },
        { text: 'Gesloten', value: 4 },
        { text: 'Niet akkoord', value: 5 },
        { text: 'Heropend', value: 6 },
        { text: 'Afgewezen', value: 8 },
        { text: 'Niet mee eens', value: 9 },
        { text: 'In afwachting', value: 10 },
        { text: 'Geschil', value: 13 },
        { text: 'Beantwoord', value: 14 }
      ],
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 150,
      align: 'center'
    },
    {
      title: 'Actie\'s',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center'
    }
  ]

  statuses = [
    'Nieuw',
    'In behandeling',
    'Akkoord',
    'Gesloten',
    'Niet akkoord',
    'Heropend',
    'Afgewezen',
    'Niet mee eens',
    'In afwachting',
    'Geschil',
    'Beantwoord'
  ]

  selectedRowKeys:number[] = []
  filtersLoaded = false

  mounted () : void {
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
    this.getFilters()
    this.onTableSettingsChange()
  }

  async getFilters () : Promise<void> {
    await this.getFilter('project/objections', 'project')
    await this.getFilter('building/questions', 'building.number', { text: 'number', value: 'number' })
    await this.getFilter('user/objections_to_manage', 'manager', { text: 'full_name', value: 'id' })
    this.filtersLoaded = true
  }

  getFilter (url:string, dataIndex: string, payload: { text: string, value: string} = { text: 'name', value: 'id' }) : void {
    axios.post(process.env.VUE_APP_API_URL + '/filters/' + url, { textColumn: payload.text, valueColumn: payload.value }).then((res) => {
      this.columns.filter(c => c.dataIndex === dataIndex)[0].filters = res.data
    })
  }

  getRowClassName (record: Record<string, unknown>): string {
    if ('read' in record) {
      return record.read ? 'read' : 'unread'
    }
    return ''
  }

  setActiveTab (tab:string) : void {
    new SecureLS({ isCompression: false }).set('serviceListActiveTab', tab)
    this.currentTab = tab
  }

  handleTableChange (pagination:{ current:number, pageSize:number }, filters:{[key: string]: number[]|string[]}, sort:{ columnKey:string, order:string }) : void {
    // Remove key from filters if filter is an empty object.
    if (filters && Object.keys(filters).length) {
      Object.keys(filters).forEach((key) => {
        if (!filters[key] || !filters[key].length) {
          delete filters[key]

          this.columns.forEach((column:Column) => {
            if (column.key === key) {
              column.filteredValue = null
            }
          })
        }
      })
    }

    // Set pagination, filters and sorting on tableSettings.
    this.tableSettings.pagination = pagination
    this.tableSettings.activeFilters = filters
    this.tableSettings.sort = sort.order ? sort : null

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  handleSearchChange () : void {
    // Set page to 1 because of new search but retain filters and sorting.
    this.tableSettings.pagination.current = 1

    // Get list based on filters and search term.
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  get activeColumns (): Column[] {
    return this.columns.filter(col => !this.$store.getters.InactiveObjectionColumns.includes(col.key))
  }

  // Required for ActiveFilters component
  updateFilters (filters: {[key: string]: number[]|string[]|boolean[]}) : void {
    const { columns } = this

    columns.forEach((column:Column) => {
      column.filteredValue = column.key in filters ? filters[column.key] : []
    })

    this.tableSettings.activeFilters = Object.assign({}, filters)
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  // Used to refresh the list when a search filter is changed.
  reload (): void {
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  filterVisible = false
  filterDropdownVisibleChange (visible:boolean) : void {
    this.filterVisible = visible
  }

  onRowClick (rowData:{id:number}) : { on: { click: () => void }} {
    return {
      on: {
        click: () => {
          const keyboard = window.event as KeyboardEvent
          if (this.authUser.permissions.includes(this.settings.permissionNames.edit)) {
            this.handleRouting(this.settings.routeNames.edit, keyboard, rowData)
          } else if (this.authUser.permissions.includes(this.settings.permissionNames.show)) {
            this.handleRouting(this.settings.routeNames.show, keyboard, rowData)
          }
        }
      }
    }
  }

  private handleRouting (routeName:string, keyboard: KeyboardEvent, rowData: { id: number }) {
    if (keyboard.ctrlKey) {
      const route = this.$router.resolve({
        name: routeName,
        params: { [this.settings.routeParamKey]: rowData.id.toString() }
      })
      window.open(route.href, '_blank')
    } else {
      this.$router.push({
        name: routeName,
        params: { [this.settings.routeParamKey]: rowData.id.toString() }
      })
    }
  }

  navigateTo (routeName:string, id:number|undefined) : void {
    if (id) {
      this.$router.push({ name: routeName, params: { [this.settings.routeParamKey]: id.toString() } })
    } else {
      this.$router.push({ name: routeName })
    }
  }

  onSelectChange (selectedRowKeys:number[]) : void {
    this.selectedRowKeys = selectedRowKeys
  }

  previousPage () : void {
    this.$router.go(-1)
  }

  get itemList () : { data: Objection[], meta: { per_page:string, total:number } } {
    return this.$store.getters[this.settings.getterNames.list]
  }

  get pagination () : Pagination {
    return {
      current: this.tableSettings.pagination.current,
      defaultPageSize: this.tableSettings.pagination.pageSize,
      pageSize: parseInt(this.itemList.meta.per_page),
      total: this.itemList.meta.total,
      showTotal: (total, range) => `${this.config.single} ${range[0]} t/m ${range[1]} van de ${total}`,
      showSizeChanger: this.tableSettings.showSizeChanger,
      pageSizeOptions: this.tableSettings.pageSizeOptions
    }
  }

  get authUser () : User {
    return new SecureLS({ isCompression: false }).get('authUser')
  }

  get loading () : boolean {
    return this.$store.getters[this.settings.getterNames.loading].list
  }

  get navigationCollapsed () : boolean {
    return this.$store.getters.navigationCollapsed
  }

  get screenWidth () : number {
    return screen.width
  }

  toggleInactiveItems (checked:boolean): void {
    this.tableSettings.archived = checked
    this.$store.dispatch(this.settings.functionNames.getList, this.tableSettings)
  }

  @Watch('tableSettings', { deep: true })
  onTableSettingsChange () : void {
    // On tableSettings change, store them in local storage.
    new SecureLS({ isCompression: false }).set(this.settings.localStorageName, this.tableSettings)

    axios.post(process.env.VUE_APP_API_URL + '/users/admin/settings', {
      settingName: this.settings.localStorageName,
      settingValue: this.tableSettings
    })

    // Set the current active filters on the columns.
    this.columns.forEach((column:Column) => {
      // Set active filters.
      if (this.tableSettings.activeFilters && column.key in this.tableSettings.activeFilters) {
        column.filteredValue = this.tableSettings.activeFilters[column.key]
      }

      // Set active sort order.
      if (this.tableSettings.sort && column.key === this.tableSettings.sort.columnKey) {
        column.defaultSortOrder = this.tableSettings.sort.order
        column.sortOrder = this.tableSettings.sort.order
      } else {
        column.sortOrder = false
      }
    })
  }
}
