






















































































/**
 * Preview Container
 * @author D.Mol
 * @version 1.0.3
 * @created 17-02-2021
 * @updated 15-01-2024
 * - Added mp4 video support
 */

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import pdf from 'vue-pdf-yzd'
import { Base64MediaFile, getBase64Async } from '@/types/media'
import MediaItem from '@/types/mediaItem'

@Component({
  components: { pdf }
})

export default class PreviewContainer extends Vue {
  @Prop({ required: true })
  public files!: File[]|MediaItem[]

  @Prop({ required: false, default: 1 }) public numColumns!: number
  @Prop({ required: false, default: 50 }) public thumbnailSize!: number
  @Prop({ required: false, type: Boolean, default: true }) public showMetaData!: boolean
  @Prop({ required: false, type: Boolean, default: false }) public permissionToDelete!: boolean
  @Prop({ default: () => ['image/jpg', 'image/jpeg', 'image/png'] }) public allowedImageTypes!: string[]
  @Prop({ default: () => ['video/mp4', 'video/m4v'] }) public allowedVideoTypes!: string[]
  @Prop({
    required: false,
    default: 'list',
    validator: function (value: string) {
      return ['list', 'grid', 'single'].includes(value)
    }
  }) public mode!: string;

  pdfMimeTypes = ['application/pdf']
  modalVisible = false
  modelTitle = ''

  pageCount = 1
  currentPage = 1
  currentIndex = 0

  thumbnails: MediaItem[] = [];
  originalFiles: File[] = [];

  @Watch('files')
  async onFilesChanged (newFiles: File[]) : Promise<void> {
    let thumbnail = null

    if (newFiles.length === 0) {
      this.thumbnails = []
      this.originalFiles = []
      return
    }

    for (const file of newFiles) {
      if (this.originalFiles.includes(file)) continue

      const thumbnailFile = file.type.includes('video') ? await this.createThumbnail(file) : file

      thumbnail = new Base64MediaFile(thumbnailFile)
      thumbnail.url = await getBase64Async(thumbnailFile)
      thumbnail.name = file.name.replace(/\.[^/.]+$/, '')

      this.originalFiles = [...this.originalFiles, file]
      this.thumbnails = [...this.thumbnails, thumbnail]
    }
  }

  mounted (): void {
    this.generateThumbnails(this.files)
  }

  get mediaFiles (): MediaItem[] {
    return this.files as MediaItem[]
  }

  get thumbnailStyle (): { [key: string]: string } {
    return {
      width: `${this.thumbnailSize}px`,
      height: `${this.thumbnailSize}px`
    }
  }

  get currentFile ():File|MediaItem {
    return this.files[this.currentIndex] || new File([], '')
  }

  get thumbnailUrl ():string {
    if (this.thumbnails.length === 0) return ''
    return this.thumbnails[this.currentIndex].url || ''
  }

  getVideoUrl (file: File|MediaItem): string {
    if (file instanceof File) return URL.createObjectURL(file)
    else return file.url
  }

  isImage (index: number): boolean {
    const file = this.files[index]
    return this.allowedImageTypes.includes(file.type)
  }

  isVideo (index: number): boolean {
    const file = this.files[index]
    return this.allowedVideoTypes.includes(file.type)
  }

  isPdf (index: number): boolean {
    const file = this.files[index]
    return this.pdfMimeTypes.includes(file.type)
  }

  deleteFile (index: number): void {
    this.thumbnails.splice(index, 1)
    this.originalFiles.splice(index, 1)
    this.$emit('delete', { index: index, file: this.files[index] })
  }

  showPreviewModal (index: number): void {
    this.currentIndex = index
    this.modelTitle = this.currentFile.name
    this.modalVisible = true
  }

  closePreview (): void {
    this.modalVisible = false
    if (this.isVideo(this.currentIndex) && this.$refs.videoPlayer) {
      const videoPlayer = this.$refs.videoPlayer as HTMLVideoElement
      videoPlayer.pause()
      videoPlayer.currentTime = 0 // Reset the video to the start
    }
  }

  generateThumbnails (files:MediaItem[]|File[]): void {
    files.forEach(async (file:MediaItem|File) => {
      if ('url' in file) {
        this.thumbnails.push(file)
      }
    })
  }

  nextPreview (): void {
    if (!this.isImage(this.currentIndex) && this.currentPage < this.pageCount) {
      this.currentPage++
    } else {
      this.currentPage = 1
      this.currentIndex++
      if (this.currentIndex > this.files.length - 1) {
        this.currentIndex = 0
      }
    }
  }

  previousPreview (): void {
    if (!this.isImage(this.currentIndex) && this.currentPage !== 1) {
      this.currentPage--
    } else {
      this.currentPage = 1
      this.currentIndex--
      if (this.currentIndex < 0) {
        this.currentIndex = this.files.length - 1
      }
    }
  }

  openBase64 (): void {
    const newWindow = window.open()
    if (newWindow) {
      newWindow.document.write(`<img src="${this.thumbnailUrl}" />`)
    }
  }

  openPdf () :void {
    if (this.isPdf(this.currentIndex)) {
      window.open(this.thumbnailUrl, '_blank')
    }
  }

  async createThumbnail (file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const video = document.createElement('video')
        if (e.target?.result) {
          video.src = e.target.result as string
        }
        video.onloadedmetadata = () => {
          video.currentTime = 0 // Adjust if you need a different frame
        }
        video.onseeked = () => {
          const canvas = document.createElement('canvas')
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          const ctx = canvas.getContext('2d')
          if (ctx) {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
            canvas.toBlob((blob) => {
              if (!blob) {
                reject(new Error("Couldn't generate blob from canvas"))
                return
              }
              // Convert the blob to a file
              const fileName = file.name.replace(/\.[^/.]+$/, '')
              const thumbnailFile = new File([blob], fileName, { type: 'image/png' })
              resolve(thumbnailFile)
            }, 'image/png')
          }
        }
      }
      reader.onerror = (error: ProgressEvent<FileReader>) => {
        reject(error)
      }
    })
  }

  get gridStyle (): { [key: string]: string } {
    return {
      gridTemplateColumns: `repeat(${this.numColumns}, 1fr)`
    }
  }
}
