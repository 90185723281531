import Model from './Model'

export default class BuildingModel extends Model {
  public id: number|undefined
  public end_modular?: string|null
  public end_carcass?: string|null
  public end_completion?: string|null
  public start_sale_date?: string|null
  public sale_date?: string|null
  public start_construction_date?: string|null
  public delivery_date?: string|null

  resource () : string {
    return 'buildings'
  }
}
