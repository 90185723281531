import Model from './Model'

export default class SurveyModel extends Model {
  public id: number|undefined
  public scheduled_at?: string|null

  resource () : string {
    return 'surveys'
  }
}
