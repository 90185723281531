import Model from './Model'

export default class IssueModel extends Model {
  public id: number|undefined
  public appointment_at?: string|null
  public estimate?: string|null

  resource () : string {
    return 'issues'
  }
}
