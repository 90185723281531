import Model from './Model'

export default class ProjectModel extends Model {
  public id: number|undefined
  public start_date?: string|null
  public start_sale_date?: string|null
  public start_construction_date?: string|null
  public end_date?: string|null

  resource () : string {
    return 'projects'
  }
}
