import Model from './Model'

export default class NewsItemModel extends Model {
  public id: number|undefined
  public publish_at?: string|null

  resource () : string {
    return 'news'
  }
}
