import Project from '@/types/project'
import Building from '@/types/building'
import Customer from '@/types/customer'
import ServiceComment from '@/types/serviceComment'
import moment from 'moment'
import Room from '@/types/room'
import User from '@/types/user'

export class Element {
  public id!:number|null
  public group?: number
  public group_text?: string
  public name_sales?: string
}

export default class ServiceItem {
  public id!:number
  public reference!:string
  public project_id: number
  public building_id: number
  public manager_id?:number
  public user_id!: number
  public additional_info!: string|null
  public recommended_solution!:string|null
  public implemented_solution!:string|null
  public title!:string
  public description!:string
  public status!: number
  public created_at?:moment.Moment|null
  public updated_at!:string
  public project!:Project
  public building!:Building
  public room!:Room
  public element!:Element
  public created_by!:User // created_by
  public customer!:Customer
  public comments!:ServiceComment[]
  public source!:string
  public has_new_message = false
  public subscribed:boolean
  public priority!:number
  public estimate?: moment.MomentInput|string|null
  public contact_name:string|null = null
  public contact_phone:string|null = null
  public contact_email:string|null = null
  public tenant_name:string|null = null
  public tenant_phone:string|null = null
  public tenant_email:string|null = null
  public tags:string[] = []
  constructor () {
    this.project = new Project()
    this.source = 'wo-admin'
    this.building_id = 0
    this.project_id = 0
    this.manager_id = 0
    this.priority = 2
    this.subscribed = false
    this.estimate = null
  }
}

export class Status {
  public id!: number
  public name!: string
  public class!: string
  public description!: string
}

export const PriorityFilter = [
  {
    text: 'Hoog',
    value: 3
  },
  {
    text: 'Normaal',
    value: 2
  },
  {
    text: 'Laag',
    value: 1
  }
]

export const ServiceClasses: Status[] = [
  {
    id: 0,
    name: 'Nieuw',
    class: 'wo-blue',
    description: 'Moet nog in behandeling worden genomen'
  },
  {
    id: 1,
    name: 'In behandeling',
    class: 'wo-blue-ol',
    description: 'Status ter melding voor de koper'
  },
  {
    id: 2,
    name: 'Opgelost',
    class: 'wo-light-green',
    description: 'Melding is naar behoren afgerond. Wordt over 5 dagen automatisch Gesloten.'
  },
  {
    id: 3,
    name: 'Akkoord',
    class: 'wo-green',
    description: 'Koper is het eens met de oplossing.'
  },
  {
    id: 4,
    name: 'Gesloten',
    class: 'wo-dark-grey',
    description: 'Melding is afgerond.'
  },
  {
    id: 5,
    name: 'Niet akkoord',
    class: 'wo-red',
    description: 'Koper is het niet eens met de oplossing (of antwoord).'
  },
  {
    id: 6,
    name: 'Heropend',
    class: 'wo-blue',
    description: 'Melding kan opnieuw in behandeling genomen worden.'
  },
  {
    id: 7,
    name: 'Gereed',
    class: 'wo-green-ol',
    description: 'Leverancier heeft zijn deel afgerond.'
  },
  {
    id: 8,
    name: 'Afgewezen',
    class: 'wo-grey',
    description: 'Melding wordt niet in behandeling worden genomen. Wordt aangemerkt als gesloten.'
  },
  {
    id: 9,
    name: 'Niet mee eens',
    class: 'wo-grey',
    description: 'Koper is het niet eens met de oplossing (of antwoord).'
  },
  {
    id: 10,
    name: 'In afwachting',
    class: 'wo-yellow',
    description: 'Er wordt gewacht op een reactie van de koper.'
  },
  {
    id: 11,
    name: 'Ingepland',
    class: 'wo-blue-ol',
    description: 'De melding is doorgestuurd naar een interne medewerker.'
  },
  {
    id: 12,
    name: 'Doorgestuurd',
    class: 'wo-blue-ol',
    description: 'De melding is doorgestuurd naar een externe partij.'
  },
  {
    id: 13,
    name: 'Geschil',
    class: 'wo-red-ol',
    description: 'Melding wordt niet in behandeling worden genomen. Melding blijft open.'
  },
  {
    id: 14,
    name: 'Beantwoord',
    class: 'wo-light-green',
    description: 'Melding naar behoren afgerond. Wordt over 5 dagen automatisch Gesloten.'
  }
]

export const ServiceStates = Object.freeze({
  Nieuw: 0,
  InBehandeling: 1,
  Opgelost: 2,
  Akkoord: 3,
  Gesloten: 4,
  NietAkkoord: 5,
  Heropend: 6,
  Gereed: 7,
  Afgewezen: 8,
  NietMeeEens: 9,
  InAfwachting: 10,
  Ingepland: 11,
  Doorgestuurd: 12,
  Geschil: 13,
  Beantwoord: 14
})

export const SortedStates = [...ServiceClasses].sort((a, b) => a.name.localeCompare(b.name))
