import MediaItem from '@/types/mediaItem'
import Entity from '@/types/entity'

export class Developer {
  id!:number
  reference!:string
  name!:string
}

export default class Project {
  [key: string]: any;
  public id!:number
  public reference!:string
  public name!:string
  public short_name!:string
  public city!:string
  public website!:string
  public email!:string
  public start_date!:string
  public start_sale_date!:string
  public start_construction_date!:string
  public end_date!:string
  public work_days!:number
  public phase!:number
  public phase_label!:string
  public entity_id!:number
  public entity!:Entity
  public developer_id!:number|null
  public developer!:Developer|null
  public in_afas!:boolean
  public last_sync_at!:string|null
  public bank_guarantee_provider!:number
  public enabled_options!:number[]
  public construction_type_ids!:number[]
  public construction_types!:string[]
  public contactFormAvailable!:boolean
  public subscribeFormAvailable!:boolean
  public spareListAvailable!:boolean
  public media!:{
    logo:MediaItem[],
    banner:MediaItem[],
    verkoopdocumentatie:MediaItem[],
    contract:MediaItem[],
    gebruikershandleiding:MediaItem[],
    revisiedocumenten:MediaItem[],
    opleverdossier:MediaItem[],
    kwaliteitdossier:MediaItem[],
    servicemeldingen:MediaItem[],
    overig:MediaItem[],
  }

  constructor () {
    this.id = 0
    this.reference = ''
    this.name = ''
  }
}

export const projectMediaFolders:{ name:string, position:string, allowedMimeTypes:string[], maxFileSize:number, allowFileRename:boolean }[] = [
  {
    name: 'Verkoopdocumentatie',
    position: 'verkoopdocumentatie',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true
  },
  {
    name: 'Contract',
    position: 'contract',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true
  },
  {
    name: 'Gebruikershandleiding',
    position: 'gebruikershandleiding',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true
  },
  {
    name: 'Revisiedocumenten',
    position: 'revisiedocumenten',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true
  },
  {
    name: 'Opleverdossier',
    position: 'opleverdossier',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true
  },
  {
    name: 'Kwaliteitdossier',
    position: 'kwaliteitdossier',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true
  },
  {
    name: 'Servicemeldingen',
    position: 'servicemeldingen',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true
  },
  {
    name: 'Overig',
    position: 'overig',
    allowedMimeTypes: ['image/jpeg', 'image/png', 'application/pdf'],
    maxFileSize: 16,
    allowFileRename: true
  }
]
