













import { Vue, Component, Prop } from 'vue-property-decorator'
import ProjectSolvers from '@/views/project/ProjectSolvers.vue'
import ProjectMembersOverview from '@/views/project/ProjectMembersOverview.vue'

@Component({
  components: { ProjectMembersOverview, ProjectSolvers }
})

export default class ProjectMembersTab extends Vue {
  @Prop({ required: false, type: Boolean, default: false }) canEdit!: boolean
}
