























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { FilterOption } from '@/types/column'
import _ from 'lodash'
import TableSettings from '@/types/tableSettings'

@Component({
  components: {}
})

export default class SearchableColumnFilter extends Vue {
  @Prop() tableSettings!: TableSettings
  @Prop() values!: FilterOption[]
  @Prop() columnKey!: string
  @Prop() filteredValue!: (number | string | boolean)[]
  @Prop() onConfirm!: () => void
  @Prop() onReset!: () => void
  @Prop({ required: false }) visible!: boolean

  @Watch('filteredValue')
  UpdateSelectedValues (): void {
    this.selectedValues = this.filteredValue ? _.cloneDeep(this.filteredValue as (number | string | boolean)[]) : []
    this.checkedValues = this.filteredValue ? _.cloneDeep(this.filteredValue as (number | string | boolean)[]) : []
  }

  @Watch('visible')
  ApplyUpdate (): void {
    if (!this.visible) {
      if (!_.isEqual(this.startingValues, this.selectedValues)) {
        this.updateCustomFilter(this.columnKey, this.selectedValues)
        return
      }
    }

    this.startingValues = _.cloneDeep(this.selectedValues)
  }

  searchValue = ''
  startingValues: (number | string | boolean)[] = []
  selectedValues: (number | string | boolean)[] = []
  checkedValues: (number | string | boolean)[] = []

  mounted (): void {
    this.UpdateSelectedValues()
    this.startingValues = _.cloneDeep(this.selectedValues)
  }

  get entries ():FilterOption[] {
    return this.values.filter(v => v.text.toString().toLowerCase().includes(this.searchValue)).map(f => { return { text: f.text, value: f.value } })
  }

  isValueSelected (value: number | string | boolean): boolean {
    return this.selectedValues.includes(value)
  }

  checkedValue (id: number | boolean | string): void {
    if (this.checkedValues.includes(id as number)) {
      this.checkedValues.splice(this.checkedValues.indexOf(id as number), 1)
    } else {
      this.checkedValues.push(id as number)
    }

    this.selectedValues = _.cloneDeep(this.checkedValues)
  }

  parseSearchValue (value: string): void {
    this.searchValue = value.toLowerCase()
  }

  clearSearch (): void {
    this.searchValue = ''
  }

  clearSelected (): void {
    this.selectedValues = this.checkedValues = []
  }

  updateCustomFilter (column: string, values:(number | string | boolean)[]): void {
    const filters = this.tableSettings.activeFilters ? _.cloneDeep(this.tableSettings.activeFilters) : {}

    filters[column] = values
    this.tableSettings.activeFilters = filters
    this.clearSearch()
    this.$emit('refresh')
  }

  resetFilters (callback: () => void): void {
    this.clearSelected()
    callback()
  }

  applyFilters (callback: () => void): void {
    callback()
  }
}
