






















import { Vue, Component, Prop } from 'vue-property-decorator'
import Issue from '@/types/issue'
import TextEditor from '@/components/base/TextEditor.vue'
import storeSettings from '@/types/storeSettings'
import moment, { Moment } from 'moment/moment'

@Component({
  components: { TextEditor }
})

export default class PrioritySelector extends Vue {
  @Prop({ required: true }) settings!: storeSettings
  @Prop({ required: false, type: Boolean, default: false }) disabled!: boolean

  mounted (): void {
    if (!this.currentItem.estimate) {
      this.updateDeadline()
    }
  }

  get currentItem (): Issue {
    return this.$store.getters['issueStore/current']
  }

  get loading (): boolean {
    return this.$store.getters['issueStore/loading'].item
  }

  updateDeadline (): void {
    let newDateTime

    switch (this.currentItem.priority) {
      case 1:
        newDateTime = moment(this.currentItem.created_at).startOf('day').add(3, 'months')
        break
      case 3:
        newDateTime = moment(this.currentItem.created_at).startOf('day').add(3, 'days')
        break
      default:
        newDateTime = moment(this.currentItem.created_at).startOf('day').add(3, 'weeks')
    }

    if (moment(newDateTime).weekday() === 6 || moment(newDateTime).weekday() === 0) {
      newDateTime = moment(newDateTime).day(1)
    }

    this.$store.dispatch('issueStore/setEstimate', newDateTime)
  }

  getPriority (date:string): number {
    const high = moment(moment.now()).startOf('day').add(3, 'days')
    const normal = moment(moment.now()).startOf('day').add(3, 'weeks')

    if (moment(date).isAfter(normal)) {
      return 1 // low
    } else if (moment(date).isBefore(high)) {
      return 3 // high
    } else {
      return 2 // normal
    }
  }

  updatePriority (date:Moment): void {
    const priority = this.getPriority(date.format('YYYY-MM-DD'))
    this.$store.dispatch('issueStore/setPriority', priority)
  }
}
