import Vue from 'vue'
import Vuex, { Module } from 'vuex'
import ConstructionWorkerModel from '@/models/ConstructionWorkerModel'
import { notification } from 'ant-design-vue'
import router from '@/router'
import moment from 'moment/moment'

Vue.use(Vuex)

export const constructionWorkerStore: Module<any, any> = {
  state: {
    constructionWorkerList: {
      data: [],
      meta: {
        per_page: 25,
        total: 0
      }
    },
    currentConstructionWorker: {
      firstname: null,
      lastname: null,
      full_name: null,
      company: { name: '' },
      nationality: { nationality: '' },
      country: { name: '' }
    },
    loading: {
      list: false,
      item: false,
      save: false
    },
    errors: {}
  },
  mutations: {
    setCurrentConstructionWorker (state, { currentConstructionWorker }) {
      currentConstructionWorker.date_of_birth = currentConstructionWorker.date_of_birth ? moment(currentConstructionWorker.date_of_birth) : null
      state.currentConstructionWorker = currentConstructionWorker
    },
    clearCurrentConstructionWorker (state) {
      state.currentConstructionWorker = {
        firstname: null,
        lastname: null,
        full_name: null,
        company: { name: '' },
        nationality: { nationality: '' },
        country: { name: '' }
      }
    },
    setConstructionWorkerList (state, { constructionWorkerList }) {
      state.constructionWorkerList = constructionWorkerList
    },
    setLoading (state, data) {
      state.loading[data.type] = data.state
    },
    setErrors (state, errors) {
      state.errors = errors
    }
  },
  actions: {
    getConstructionWorkerList ({ commit }, data) {
      commit('setLoading', { type: 'list', state: true })
      let constructionWorkerModel = new ConstructionWorkerModel()

      // Add filters if there are any filters set.
      if (data.activeFilters) {
        Object.keys(data.activeFilters).forEach(key => {
          if (data.activeFilters[key].length) {
            constructionWorkerModel = constructionWorkerModel.whereIn(key, data.activeFilters[key])
          }
        })
      }

      // Add search if available.
      if (data.search) {
        constructionWorkerModel = constructionWorkerModel.where('search', data.search)
      }

      // Add orderBy if sort is set.
      if (data.sort) {
        constructionWorkerModel = constructionWorkerModel.orderBy(data.sort.order === 'ascend' ? data.sort.columnKey : '-' + data.sort.columnKey)
      }

      if (data.pagination) {
        constructionWorkerModel = constructionWorkerModel.limit(data.pagination.pageSize).page(data.pagination.current)
      }

      constructionWorkerModel.get().then((constructionWorkerList) => {
        commit('setConstructionWorkerListList', { constructionWorkerList })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van bouwplaatsmedewerkers!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'list', state: false })
      })
    },
    setCurrentConstructionWorker ({ commit }, id) {
      commit('setErrors', {})
      commit('setLoading', { type: 'item', state: true })
      ConstructionWorkerModel.$find(id).then((currentConstructionWorker) => {
        commit('setCurrentConstructionWorker', { currentConstructionWorker })
      }).catch(() => {
        notification.error({ message: 'Fout tijdens het ophalen van deze bouwplaatsmedewerker!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
      }).finally(() => {
        commit('setLoading', { type: 'item', state: false })
      })
    },
    clearCurrentConstructionWorker ({ commit }) {
      commit('clearCurrentConstructionWorker')
    },
    saveConstructionWorker ({ commit }, data) {
      commit('setLoading', { type: 'save', state: true })
      const constructionWorkerModel = new ConstructionWorkerModel(data)
      constructionWorkerModel.date_of_birth = data.date_of_birth ? moment(data.date_of_birth).format('YYYY-MM-DD HH:mm:ss') : null

      // Save constructionWorker.
      constructionWorkerModel.save().then((currentConstructionWorker) => {
        commit('setErrors', {})
        commit('setCurrentConstructionWorker', { currentConstructionWorker })
        if (data.id) {
          notification.success({ message: 'Gebruiker opgeslagen.', description: 'De gebruiker is succesvol opgeslagen.', duration: 3 })
        } else {
          notification.success({ message: 'Gebruiker toegevoegd.', description: 'De gebruiker is succesvol toegevoegd.', duration: 3 })
          if (currentConstructionWorker.id) {
            router.push({ name: 'constructionWorkerEdit', params: { constructionWorkerId: currentConstructionWorker.id.toString() } })
          }
        }
      }).catch((e) => {
        if (e.response.status === 422) {
          commit('setErrors', e.response.data.errors)
          notification.error({ message: 'Fout tijdens het opslaan van deze gebruiker!', description: 'Niet alle velden zijn correct ingevuld.', duration: 3 })
        } else {
          notification.error({ message: 'Fout tijdens het opslaan van deze gebruiker!', description: 'Er is iets mis gegaan. Probeer het later nog een keer.', duration: 3 })
        }
      }).finally(() => {
        commit('setLoading', { type: 'save', state: false })
      })
    }
  },
  getters: {
    constructionWorkerList: state => state.constructionWorkerList,
    currentConstructionWorker: state => state.currentConstructionWorker,
    loadingConstructionWorker: state => state.loading,
    constructionWorkerErrors: state => state.errors
  }
}
