
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ServiceItem from '@/types/serviceItem'
import axios from 'axios'

@Component({
  components: { }
})

export default class ServiceContactInfo extends Vue {
  @Prop({ required: true }) loading!: boolean
  @Prop({ required: true, default: () => new ServiceItem() }) current!: ServiceItem
  @Prop({ required: true }) store!: string

  columnWidth = { labels: 10, values: 14 }
  contacts: { name: string, phone: string, email: string }[] = []
  mainContact: { name: string, phone: string, email: string } = { name: '', phone: '', email: '' }
  selectedIndex = 0
  loadingContacts = false

  emptyContact = { name: '', phone: '', email: '' }

  @Watch('current.building_id', { deep: true })
  onCustomerChange (): void {
    if (this.current.building_id) {
      this.getContacts(this.current.building_id)
    }
  }

  mounted () : void {
    if (this.current.building_id) {
      this.getContacts(this.current.building_id)
    }
  }

  getContacts (id: number): void {
    this.loadingContacts = true
    axios.get(`${process.env.VUE_APP_API_URL}/buildings/${id}/contacts`)
      .then(response => {
        this.contacts = response.data.others
        this.mainContact = response.data.main
        this.updateContactDetails()
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        this.loadingContacts = false
      })
  }

  updateContactDetails (): void {
    if (this.current.id) return
    this.setContactDetails()
  }

  cachedContactDetails: { name: string, phone: string, email: string } = { name: '', phone: '', email: '' } // Cache the contact details to prevent overwriting them when switching between contacts
  contactDetailsCached = false

  setContactDetails (): void {
    if (this.current.contact_name !== '' || this.current.contact_phone !== '' || this.current.contact_email !== '') {
      this.cacheContactDetails()
    }

    if (this.selectedIndex === this.contacts.length + 1) {
      this.current.contact_name = ''
      this.current.contact_phone = ''
      this.current.contact_email = ''
    } else if (this.selectedIndex === 0) {
      this.current.contact_name = this.mainContact.name
      this.current.contact_phone = this.mainContact.phone
      this.current.contact_email = this.mainContact.email
    } else {
      this.current.contact_name = this.contacts[this.selectedIndex - 1].name
      this.current.contact_phone = this.contacts[this.selectedIndex - 1].phone
      this.current.contact_email = this.contacts[this.selectedIndex - 1].email
    }
  }

  cacheContactDetails (): void {
    if (this.contactDetailsCached) return
    this.cachedContactDetails.name = this.current.contact_name ?? ''
    this.cachedContactDetails.phone = this.current.contact_phone ?? ''
    this.cachedContactDetails.email = this.current.contact_email ?? ''
    this.contactDetailsCached = true
  }

  undoContactDetails (): void {
    if (!this.contactDetailsCached) return
    this.current.contact_name = this.cachedContactDetails.name
    this.current.contact_phone = this.cachedContactDetails.phone
    this.current.contact_email = this.cachedContactDetails.email
    this.contactDetailsCached = false
  }

  get hasContactDetails (): boolean {
    const cached = {
      name: this.cachedContactDetails.name,
      phone: this.cachedContactDetails.phone,
      email: this.cachedContactDetails.email
    }

    const current = {
      name: this.current.contact_name,
      phone: this.current.contact_phone,
      email: this.current.contact_email
    }

    return JSON.stringify(cached) !== JSON.stringify(current) && JSON.stringify(cached) !== JSON.stringify(this.emptyContact)
  }

  get hasValidEmail (): boolean {
    const isValid = !this.current.contact_email || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.current.contact_email.trim())

    if (isValid) {
      this.$store.commit(`${this.store}/removeError`, 'Ongeldig e-mailadres')
    } else {
      this.$store.commit(`${this.store}/addError`, 'Ongeldig e-mailadres')
    }

    return isValid
  }
}
