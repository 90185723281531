




















import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import ProjectMemberGroup from '@/views/project/ProjectMemberGroup.vue'

interface ProjectMember {
  user_id: number
  user_name: string
  group_id: number
  active: boolean
}

interface ProjectGroup {
  id: number
  role: string
  label: string
  multiple: boolean
}

@Component({
  components: { ProjectMemberGroup }
})

export default class ProjectMembersOverview extends Vue {
  @Prop({ required: false, type: Boolean, default: false }) canEdit!: boolean

  members: ProjectMember [] = []
  groups: ProjectGroup [] = []
  loadingMembers = false

  async mounted (): Promise<void> {
    if (!this.projectId) return
    await this.getMembers()
  }

  get projectId (): number {
    return this.$store.getters.currentProject.id
  }

  onMemberAdded (member: ProjectMember): void {
    this.members.push(member)
  }

  onMemberRemoved (member: ProjectMember): void {
    this.members = this.members.filter(m => m !== member)
  }

  async getMembers (): Promise<void> {
    this.loadingMembers = true
    await axios.get(`${process.env.VUE_APP_API_URL}/members/${this.projectId}/overview`)
      .then(res => {
        this.members = res.data.members
        this.groups = res.data.groups
      })
      .finally(() => {
        this.loadingMembers = false
      })
  }
}
